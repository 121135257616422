<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="层级名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="层级名称"></el-input>
    </el-form-item>
<!--    <el-form-item label="是否有对应hase表" prop="hasHbasetable">-->
<!--      <el-radio-group v-model="dataForm.hasHbasetable">-->
<!--        <el-radio :label=1>存在</el-radio>-->
<!--        <el-radio :label=0>不存在</el-radio>-->
<!--      </el-radio-group>-->
<!--    </el-form-item>-->
      <el-form-item label="父层级名称" prop="parentId">
        <el-popover
            ref="menuListPopover"
            placement="bottom-start"
            trigger="click">
          <el-tree
              :data="dataList"
              :props="menuListTreeProps"
              node-key="id"
              ref="menuListTree"
              @current-change="menuListTreeCurrentChangeHandle"
              :default-expand-all="true"
              :highlight-current="true"
              :expand-on-click-node="false"
          :check-on-click-node="true">
          </el-tree>
        </el-popover>
        <el-input v-model="dataForm.parentName" v-popover:menuListPopover :readonly="true" placeholder="点击选择父层级名称" class="menu-list__input"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {treeDataTranslate} from "@/utils";

  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: 0,
          parentId: '',
          name: '',
          hasHbasetable: '',
          uid: '',
          parentName: "",
        },
        dataRule: {
          parentName: [
            { required: true,  message: '父层级不能为空', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '层级名称不能为空', trigger: 'blur' },
            {min: 1,max: 20,trigger: 'blur', message: '字符不能超过20个'}
          ],
          hasHbasetable: [
            { required: true, message: '是否有对应hase表不能为空', trigger: 'blur' }
          ],
        },
        dataList: [],
        menuListTreeProps: {
          label: 'name',
          children: 'children'
        }
      }
    },
    created() {
      this.currOperator = JSON.parse(
          window.sessionStorage.getItem("user")
      ).userId;
      this.currOperatorName = JSON.parse(
          window.sessionStorage.getItem("user")
      ).username;
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl('/level/listParentWithZero'),
          method: 'get',
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = treeDataTranslate(data.levelList, 'id')
          }
        }).then(() => {
            this.visible = true
            this.$nextTick(() => {
              this.$refs['dataForm'].resetFields()
            })
          }).then(() => {
          if (!this.dataForm.id) {
            // 新增
            this.menuListTreeSetCurrentNode()
          } else {  // 修改
            this.$http({
              url: this.$http.adornUrl(`/level/getById?id=${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              this.dataForm.id = data.levelEntity.id
              this.dataForm.name = data.levelEntity.name
              this.dataForm.parentId = data.levelEntity.parentId
              this.menuListTreeSetCurrentNode()
              // this.dataForm.hasHbasetable = data.levelEntity.hasHbasetable
              this.dataForm.hasHbasetable = 1
              console.log(this.dataForm.hasHbasetable)
            })
          }
        })
      },
      // 菜单树选中
      menuListTreeCurrentChangeHandle (data, node) {
        this.dataForm.parentId = data.id
        this.dataForm.parentName = data.name
      },
      // 菜单树设置当前选中节点
      menuListTreeSetCurrentNode () {
        this.$refs.menuListTree.setCurrentKey(this.dataForm.parentId)
        this.dataForm.parentName = (this.$refs.menuListTree.getCurrentNode() || {})['name']
      },
      // 图标选中
      iconActiveHandle (iconName) {
        this.dataForm.icon = iconName
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$http({
              url: this.$http.adornUrl(`/level/${!this.dataForm.id ? 'save' : 'update'}`),
              method: 'post',
              data: this.$http.adornData({
                'uid': this.currOperator,
                'id': this.dataForm.id || undefined,
                'parentId': this.dataForm.parentId,
                'name': this.dataForm.name,
                // 'hasHbasetable': this.dataForm.hasHbasetable,
                'hasHbasetable': 1,
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      }
    }
  }
</script>
